var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sanitizeUrl = void 0;
var invalidProtocolRegex = /^([^\w]*)(javascript|data|vbscript)/im;
var htmlEntitiesRegex = /&#(\w+)(^\w|;)?/g;
var htmlCtrlEntityRegex = /&(newline|tab);/gi;
var ctrlCharactersRegex = /[\u0000-\u001F\u007F-\u009F\u2000-\u200D\uFEFF]/gim;
var urlSchemeRegex = /^.+(:|&colon;)/gim;
var relativeFirstCharacters = [".", "/"];

function isRelativeUrlWithoutProtocol(url) {
  return relativeFirstCharacters.indexOf(url[0]) > -1;
} // adapted from https://stackoverflow.com/a/29824550/2601552


function decodeHtmlCharacters(str) {
  return str.replace(htmlEntitiesRegex, function (match, dec) {
    return String.fromCharCode(dec);
  });
}

function sanitizeUrl(url) {
  var sanitizedUrl = decodeHtmlCharacters(url || "").replace(htmlCtrlEntityRegex, "").replace(ctrlCharactersRegex, "").trim();

  if (!sanitizedUrl) {
    return "about:blank";
  }

  if (isRelativeUrlWithoutProtocol(sanitizedUrl)) {
    return sanitizedUrl;
  }

  var urlSchemeParseResults = sanitizedUrl.match(urlSchemeRegex);

  if (!urlSchemeParseResults) {
    return sanitizedUrl;
  }

  var urlScheme = urlSchemeParseResults[0];

  if (invalidProtocolRegex.test(urlScheme)) {
    return "about:blank";
  }

  return sanitizedUrl;
}

exports.sanitizeUrl = sanitizeUrl;
export default exports;
export const __esModule = exports.__esModule;
const _sanitizeUrl = exports.sanitizeUrl;
export { _sanitizeUrl as sanitizeUrl };